import { Injectable } from '@angular/core';

import { ApiService } from '../services/api.service';
import { PdfData, PdfGeneratorService } from '../services/pdf-generator.service';
import { SettingsService } from '../services/settings.service';
import { IconLibrary } from '../shared/icon/icon-library';
import { Lookup } from '../utility/helpers/lookup';
import { Hotspot } from './hotspot';
import { KRPanoService } from './krpano.service';
import { Option } from './option';
import { OptionCombination } from './option-combination';
import { IPano, Pano } from './pano';
import { PanoBlend } from './pano-blend';
import { View } from './view';

import { PanoHotspotAnimationType, SettingsCategoryTitle, sortByOrder } from '@ml/common';

export interface IPanoSelections {
  panoId: number;
  tourId: number;
  viewId: number;
  optComboId: number;
}

@Injectable({
  providedIn: 'root'
})
export class PanoService {
  pano: Pano;
  gyroEnabled = false;
  previousView: View;
  startInitialPanoApiRequestTime: Date;
  endInitialPanoApiRequestTime: Date;

  private panoSelections: IPanoSelections;

  constructor(
    private pdfService: PdfGeneratorService,
    private lookup: Lookup,
    private apiService: ApiService,
    private krpanoService: KRPanoService,
    private settingsService: SettingsService
  ) {}

  async getPanoData(
    panoParam: number | string | string[],
    isDynamicPano: boolean = false,
    dynamicViewId = 0
  ): Promise<Pano> {
    if (this.pano) {
      return Promise.resolve(this.pano);
    }

    let promise: Promise<IPano>;

    if (panoParam instanceof Array) {
      if (!panoParam.length)
        throw Error('Unable to determine pano parm to use for getting data from API');

      // if the array is more than one then the assumption is the route path is in format '/client/project-name/pano-name'
      if (panoParam.length === 1)
        promise = this.apiService.getPanoByName(panoParam[0], isDynamicPano);
      else
        promise = this.apiService.getPanoByName(
          panoParam[2],
          isDynamicPano,
          panoParam[0],
          panoParam[1]
        );
    } else {
      promise =
        typeof panoParam === 'number'
          ? this.apiService.getPanoById(panoParam, isDynamicPano, dynamicViewId)
          : this.apiService.getPanoByName(panoParam, isDynamicPano);
    }

    this.startInitialPanoApiRequestTime = new Date();
    const panoDto = await promise;

    this.endInitialPanoApiRequestTime = new Date();
    this.pano = new Pano(panoDto);
    this.pano.IsDynamic = isDynamicPano;

    if (isDynamicPano) {
      this.pano.UseLeftMenu = false;
    }

    return this.pano;
  }

  async getPanoDataByNameAndProjectName(panoName: string, projectName: string) {
    return this.apiService;
  }

  getAllViews(): View[] {
    return this.pano.Tours.flatMap(t => t.Views.sort(sortByOrder));
  }

  getNextViewInOrder(currentViewId: number) {
    const views = this.getAllViews();
    const i = views.findIndex(v => v.ViewId === currentViewId);
    if (views[i + 1]) return views[i + 1];
    else return views[0];
  }

  setGyroscope(value: boolean) {
    this.krpanoService.setGyroscope(value);
  }

  toggleGyroscope(): boolean {
    return this.krpanoService.toggleGyroscope();
  }

  getBasePath(): string {
    return this.lookup.getBasePanoPath(this.pano);
  }

  updateImagePathWithBasePath(path: string, basePath: string): string {
    let result = path?.slice(0) || '';

    if (result.indexOf('undefined') > -1) {
      result = '';
    }

    if (result && result.indexOf('http') === -1) {
      result = basePath + result;
    }

    return result;
  }

  async savePDF(view: View) {
    const pdf = new PdfData(this.pano, view, this.krpanoService.krpano.querySelector('canvas'));

    const logo = this.settingsService.get('PdfHeaderLogo', SettingsCategoryTitle.PanoViewerTheme);
    if (logo) {
      await pdf.loadLogo(logo);
    }

    this.pdfService.generatePdfAndDownload(pdf);
  }

  saveImage(view: View) {
    if (view) {
      const canvas = this.krpanoService.krpano.querySelector('canvas');

      if (canvas) {
        const imgDataUrl = canvas.toDataURL('image/png');

        const a = document.createElement('a');
        a.href = imgDataUrl;
        a.setAttribute('download', `${view.Name}.png`);
        a.click();
      }
    }
  }

  updateSceneByView(view: View, blend?: PanoBlend) {
    const optionCombo =
      this.findLinkedOptions(view, this.previousView) ||
      view.findOptionCombinationByToggledOnOptions() ||
      view.getDefaultOptionCombination();

    if (optionCombo) {
      this.updateSceneByOptionCombination(optionCombo, view, blend);
    }
  }

  updateSceneByOptionCombination(optionCombo: OptionCombination, view: View, blend?: PanoBlend) {
    const keepLookAt = this.setKeepLookAt(this.previousView, view, this.pano);

    view.toggleOptionsByOptionCombinationId(optionCombo.OptionCombinationId);

    this.krpanoService.loadSceneByName(
      optionCombo.SceneName,
      view,
      blend || this.pano.MainBlend,
      keepLookAt
    );

    this.setSelections(this.pano.PanoId, view.TourId, view.ViewId, optionCombo.OptionCombinationId);

    view.Categories.flatMap(x => x.Options).forEach(
      o => (o.IsSelected = optionCombo.OptionIds.includes(o.OptionId))
    );

    this.updateAllHotspotIcons(view, this.pano);

    this.previousView = view;
    this.removeExcessSpinnerHotspots(view, optionCombo);
  }

  setKeepLookAt(previousView: View, view: View, pano: Pano) {
    const sameView = previousView && previousView.ViewId === view.ViewId;

    return (
      (sameView && pano.KeepHoriztonalVerticalStatesBetweenViews) ||
      (previousView && previousView.KeepLookAtViews.indexOf(view.ViewId) > -1)
    );
  }

  removeExcessSpinnerHotspots(view: View, optionCombo: OptionCombination) {
    if (optionCombo.isASpinner || view.Hotspots.some(hs => hs.FixedPosition)) {
      const krPanoHotspots = this.krpanoService.hotspotsInView();

      // When spinning occurs, the static positioned hotspots (keep="true" in tour.xml)
      // like to blink in and out. By having them static during view change, the hotspots dont flicker.
      // But then, the old ones need to be removed so that the new ones and their actions can take place.

      // Second issue, https://panoviewer.ml3ds-iconstage.com/dynamicpano/10/lobby
      // we allow users to dynamically add images. We do not want this functionality below to run since it
      // removed the dynamic image during a pano transition from one scene to the next. PBI: 42925

      // Third issue, need to verify instanced hotspots were checked against within optioncombinations.
      // PBI 43860

      for (const krhs of Array.from(krPanoHotspots)) {
        const hs =
          view.Hotspots.find(hotspot => krhs.name === `hotspot_${hotspot.HotspotId}`) ||
          optionCombo.Hotspots.find(hotspot => krhs.name === `hotspot_${hotspot.HotspotId}`);

        if (!hs) {
          this.krpanoService.removeHotspotFromView(krhs.name);
        }
      }
    }
  }

  updateHotspotIcon(hotspot: Hotspot, view: View) {
    const element: HTMLElement = this.krpanoService.findHotspotElement(hotspot);
    if (element) {
      element.classList.add('hotspot');
      element.classList.add(`hotspot-${hotspot.HotspotId}`);

      this.removeChildrenNodes(element);

      if (hotspot.IconUrl) {
        this.insertImageIcon(hotspot.IconUrl, element);
      }

      if (hotspot.FixedPosition) {
        element.classList.add(`fixed`);

        const height = isNaN(parseFloat(hotspot.Height?.toString()))
          ? 60
          : Math.round(hotspot.Height);
        const width = isNaN(parseFloat(hotspot.Width?.toString())) ? 60 : Math.round(hotspot.Width);
        element.classList.add(`height-${height}`);
        element.classList.add(`width-${width}`);

        const top = Math.round(parseFloat(hotspot.ATV));
        const left = Math.round(parseFloat(hotspot.ATH));
        element.style.top = `${top}%`;
        element.style.left = `${left}%`;
      }

      this.setHotspotAnimation(hotspot, element);
    }
  }

  private insertImageIcon(iconUrl: string, element: HTMLElement) {
    const innerElement = document.createElement('div');
    element.appendChild(innerElement);
    innerElement.style.backgroundImage = `url('${iconUrl}')`;
    innerElement.style.backgroundRepeat = 'no-repeat';
    innerElement.style.backgroundPosition = 'center';
    innerElement.style.backgroundSize = 'contain';
    innerElement.style.pointerEvents = 'none';
    innerElement.style.width = '100%';
    innerElement.style.height = '100%';
    element.style.background = 'none';
  }

  updateAllHotspotIcons(view: View, pano: Pano) {
    view?.Hotspots.forEach(hotspot => {
      this.updateHotspotIcon(hotspot, view);
    });

    if (pano?.Settings.IconUnderMouseSettings.Enabled) {
      this.updateIconUnderMouseIcon(pano);
    }
  }

  updateIconUnderMouseIcon(pano: Pano) {
    const element = this.krpanoService.getIconUnderMouseElement();
    if (element) {
      element.classList.add('hotspot');
      this.removeChildrenNodes(element);

      if (pano.Settings.IconUnderMouseSettings.Url.trim()) {
        this.insertImageIcon(pano.Settings.IconUnderMouseSettings.Url, element);
      }
    }
  }

  setHotspotAnimation(hotspot: Hotspot, element: HTMLElement) {
    if (element) {
      switch (hotspot.AnimationSettings.Type) {
        case PanoHotspotAnimationType.OpacityPulse:
          if (typeof hotspot.AnimationSettings.StartValue === 'number')
            element.style.setProperty('--opacityStart', `${hotspot.AnimationSettings.StartValue}`);
          if (typeof hotspot.AnimationSettings.EndValue === 'number')
            element.style.setProperty('--opacityEnd', `${hotspot.AnimationSettings.EndValue}`);
          element.classList.add('pulse-opacity');
          break;

        case PanoHotspotAnimationType.SizePulse:
          if (typeof hotspot.AnimationSettings.StartValue === 'number')
            element.style.setProperty('--scaleStart', `${hotspot.AnimationSettings.StartValue}`);
          if (typeof hotspot.AnimationSettings.EndValue === 'number')
            element.style.setProperty('--scaleEnd', `${hotspot.AnimationSettings.EndValue}`);
          element.classList.add('pulse-scale');
          break;

        case PanoHotspotAnimationType.MouseOverScale:
          if (typeof hotspot.AnimationSettings.StartValue === 'number')
            element.style.setProperty('--scaleStart', `${hotspot.AnimationSettings.StartValue}`);
          if (typeof hotspot.AnimationSettings.EndValue === 'number')
            element.style.setProperty('--scaleEnd', `${hotspot.AnimationSettings.EndValue}`);
          element.classList.add('pulse-scale-hover');
          break;
      }
    }
  }

  removeHotspotAnimation(hotspot: Hotspot, element: HTMLElement) {
    if (element) {
      switch (hotspot.AnimationSettings.Type) {
        case PanoHotspotAnimationType.OpacityPulse:
          if (typeof hotspot.AnimationSettings.StartValue === 'number')
            element.style.removeProperty('--opacityStart');
          if (typeof hotspot.AnimationSettings.EndValue === 'number')
            element.style.removeProperty('--opacityEnd');
          element.classList.remove('pulse-opacity');
          break;

        case PanoHotspotAnimationType.SizePulse:
          if (typeof hotspot.AnimationSettings.StartValue === 'number')
            element.style.removeProperty('--scaleStart');
          if (typeof hotspot.AnimationSettings.EndValue === 'number')
            element.style.removeProperty('--scaleEnd');
          element.classList.remove('pulse-scale');
          break;
      }
    }
  }

  removeChildrenNodes(node: Element) {
    while (node.firstChild) {
      node.removeChild(node.firstChild);
    }
  }

  getScaleValue(transform: string): string {
    let result = '1';

    const innerString = transform.match(/scale\(([^)]*)\)/);
    if (innerString && innerString.length > 1) {
      const [scaleX, scaleY] = innerString[1].split(',');

      if (scaleX) {
        const val = parseFloat(scaleX);
        result = val < 1 ? (10 / val).toPrecision(8) : '1.2';
      }

      if (scaleY) {
        const val = parseFloat(scaleY);
        result += ', ' + (val < 1 ? (10 / val).toPrecision(8) : '1.2');
      }
    }

    return result;
  }

  trimBackground(background: string): string {
    const urlString = background.match(/url\(["|']([^"|']*)/);
    if (urlString) return `url('${urlString[1]}')`;

    return background;
  }

  findLinkedOptions(view: View, previousView: View): OptionCombination {
    let result: OptionCombination = null;

    if (view && previousView && view.ViewId !== previousView.ViewId) {
      const selectedOptions = previousView.Categories.map(cat => cat.Options)
        .reduce((prev: Option[], curr: Option[]) => [...curr, ...prev], new Array<Option>())
        .filter(opt => opt.IsSelected && opt.LinkedOptionIds?.length > 0);

      if (selectedOptions.length > 0) {
        let selectedOptionCombinationIndex = -1;
        let maxCount = 0;

        for (let i = 0; i < view.OptionCombinations.length; ++i) {
          let count = 0;

          for (const option of selectedOptions) {
            for (const optionId of option.LinkedOptionIds) {
              if (view.OptionCombinations[i].OptionIds.indexOf(optionId) > -1) {
                ++count;
              }
            }
          }

          if (count > maxCount) {
            maxCount = count;
            selectedOptionCombinationIndex = i;
          }
        }

        if (selectedOptionCombinationIndex > -1)
          result = view.OptionCombinations[selectedOptionCombinationIndex];
      }
    }

    return result;
  }

  setSelections(panoId: number, tourId: number, viewId: number, optComboId: number) {
    this.panoSelections = { panoId, tourId, viewId, optComboId };
  }

  getSelections(): IPanoSelections {
    return this.panoSelections;
  }
}
